import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  style: {'display':'flex',
               'align-items':'center'},
  class: "row mb-10"
}
const _hoisted_2 = { class: "col span-6" }
const _hoisted_3 = { class: "col span-3" }
const _hoisted_4 = { class: "col span-3" }
const _hoisted_5 = { class: "row mb-10" }
const _hoisted_6 = { class: "col span-6" }
const _hoisted_7 = { class: "col span-6" }
const _hoisted_8 = { class: "row mb-10" }
const _hoisted_9 = { class: "col span-6" }
const _hoisted_10 = {
  key: 1,
  class: "row mb-10"
}
const _hoisted_11 = { class: "col span-6" }
const _hoisted_12 = { class: "col span-6" }
const _hoisted_13 = { class: "text-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Banner = _resolveComponent("Banner")!
  const _component_LabeledSelect = _resolveComponent("LabeledSelect")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_RadioGroup = _resolveComponent("RadioGroup")!
  const _component_LabeledInput = _resolveComponent("LabeledInput")!
  const _component_KeyValue = _resolveComponent("KeyValue")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.canUpgrade && _ctx.hasUpgradesAvailable)
      ? (_openBlock(), _createBlock(_component_Banner, {
          key: 0,
          color: "info",
          "label-key": "eks.version.upgradeDisallowed",
          "data-testid": "eks-version-upgrade-disallowed-banner"
        }))
      : _createCommentVNode("", true),
    _cache[12] || (_cache[12] = _createTextVNode()),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_LabeledSelect, {
          value: _ctx.kubernetesVersion,
          options: _ctx.versionOptions,
          "label-key": "eks.version.label",
          mode: _ctx.mode,
          loading: _ctx.loadingVersions,
          taggable: true,
          searchable: true,
          "data-testid": "eks-version-dropdown",
          disabled: !_ctx.canUpgrade && _ctx.hasUpgradesAvailable,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:kubernetesVersion', $event)))
        }, null, 8, ["value", "options", "mode", "loading", "disabled"])
      ]),
      _cache[9] || (_cache[9] = _createTextVNode()),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Checkbox, {
          mode: _ctx.mode,
          "label-key": "eks.enableNetworkPolicy.label",
          value: _ctx.enableNetworkPolicy,
          disabled: !_ctx.isNewOrUnprovisioned,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:enableNetworkPolicy', $event)))
        }, null, 8, ["mode", "value", "disabled"])
      ]),
      _cache[10] || (_cache[10] = _createTextVNode()),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_Checkbox, {
          mode: _ctx.mode,
          "label-key": "eks.ebsCSIDriver.label",
          value: _ctx.ebsCSIDriver,
          disabled: !_ctx.isNewOrUnprovisioned,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:ebsCSIDriver', $event)))
        }, null, 8, ["mode", "value", "disabled"])
      ])
    ]),
    _cache[13] || (_cache[13] = _createTextVNode()),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_RadioGroup, {
          value: _ctx.customServiceRole,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.customServiceRole) = $event)),
          mode: _ctx.mode,
          options: _ctx.serviceRoleOptions,
          name: "serviceRoleMode",
          "data-testid": "eks-service-role-radio",
          disabled: _ctx.mode!=='create'
        }, null, 8, ["value", "mode", "options", "disabled"])
      ]),
      _cache[11] || (_cache[11] = _createTextVNode()),
      _createElementVNode("div", _hoisted_7, [
        (_ctx.customServiceRole)
          ? (_openBlock(), _createBlock(_component_LabeledSelect, {
              key: 0,
              value: _ctx.serviceRole,
              mode: _ctx.mode,
              disabled: _ctx.mode!=='create',
              options: _ctx.eksRoles,
              "option-label": "RoleName",
              "option-key": "RoleId",
              "label-key": "eks.serviceRole.label",
              loading: _ctx.loadingIam,
              "data-testid": "eks-service-role-dropdown",
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:serviceRole', $event.RoleName)))
            }, null, 8, ["value", "mode", "disabled", "options", "loading"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _cache[14] || (_cache[14] = _createTextVNode()),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_Checkbox, {
          value: _ctx.secretsEncryption,
          disabled: _ctx.mode!=='create',
          mode: _ctx.mode,
          "label-key": "eks.encryptSecrets.label",
          "data-testid": "eks-secrets-encryption-checkbox",
          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('update:secretsEncryption', $event)))
        }, null, 8, ["value", "disabled", "mode"])
      ])
    ]),
    _cache[15] || (_cache[15] = _createTextVNode()),
    (_ctx.secretsEncryption)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            (_ctx.canReadKms)
              ? (_openBlock(), _createBlock(_component_LabeledSelect, {
                  key: 0,
                  value: _ctx.kmsKey,
                  mode: _ctx.mode,
                  options: _ctx.kmsOptions,
                  loading: _ctx.loadingKms,
                  label: _ctx.t('cluster.machineConfig.amazonEc2.kmsKey.label'),
                  "data-testid": "eks-kms-dropdown",
                  disabled: _ctx.mode!=='create',
                  "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('update:kmsKey', $event)))
                }, null, 8, ["value", "mode", "options", "loading", "label", "disabled"]))
              : (_openBlock(), _createBlock(_component_LabeledInput, {
                  key: 1,
                  value: _ctx.kmsKey,
                  mode: _ctx.mode,
                  label: _ctx.t('cluster.machineConfig.amazonEc2.kmsKey.label'),
                  tooltip: _ctx.t('cluster.machineConfig.amazonEc2.kmsKey.text'),
                  "data-testid": "eks-kms-input",
                  disabled: _ctx.mode!=='create',
                  "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('update:kmsKey', $event)))
                }, null, 8, ["value", "mode", "label", "tooltip", "disabled"]))
          ])
        ]))
      : _createCommentVNode("", true),
    _cache[16] || (_cache[16] = _createTextVNode()),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_KeyValue, {
        value: _ctx.tags,
        mode: _ctx.mode,
        title: _ctx.t('eks.tags.label'),
        "as-map": true,
        "read-allowed": false,
        "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('update:tags', $event)))
      }, {
        title: _withCtx(() => [
          _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.t('eks.tags.label')), 1)
        ]),
        _: 1
      }, 8, ["value", "mode", "title"])
    ])
  ]))
}