import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "row mb-10" }
const _hoisted_2 = { class: "col span-6" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "col span-6" }
const _hoisted_5 = {
  key: 0,
  class: "col span-6"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RadioGroup = _resolveComponent("RadioGroup")!
  const _component_LabeledSelect = _resolveComponent("LabeledSelect")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_RadioGroup, {
          value: _ctx.scopeMode,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.scopeMode) = $event)),
          label: _ctx.t('gke.authScopes.modeLabel'),
          name: "scope-mode",
          mode: _ctx.mode,
          options: _ctx.scopeModeOptions,
          disabled: _ctx.disabled
        }, null, 8, ["value", "label", "mode", "options", "disabled"])
      ])
    ]),
    _cache[2] || (_cache[2] = _createTextVNode()),
    (_ctx.scopeMode==='custom')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(_ctx.formOptions), (scopeKey, index) => {
            return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
              (!(index%2))
                ? (_openBlock(), _createElementBlock("div", {
                    key: scopeKey,
                    class: "row mb-10"
                  }, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_LabeledSelect, {
                        value: _ctx.getScopeValue(scopeKey),
                        "get-option-label": opt=> _ctx.t(opt.labelKey),
                        options: _ctx.formOptions[scopeKey],
                        mode: _ctx.mode,
                        "label-key": `gke.authScopes.scopes."${scopeKey}"`,
                        disabled: _ctx.disabled,
                        onSelecting: ($event: any) => (_ctx.setScopeValue(scopeKey, $event.value))
                      }, null, 8, ["value", "get-option-label", "options", "mode", "label-key", "disabled", "onSelecting"])
                    ]),
                    _cache[1] || (_cache[1] = _createTextVNode()),
                    (_ctx.nextScopeKey(index))
                      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createVNode(_component_LabeledSelect, {
                            value: _ctx.getScopeValue(_ctx.nextScopeKey(index)),
                            "get-option-label": opt=> _ctx.t(opt.labelKey),
                            options: _ctx.formOptions[_ctx.nextScopeKey(index)],
                            mode: _ctx.mode,
                            "label-key": `gke.authScopes.scopes."${_ctx.nextScopeKey(index)}"`,
                            disabled: _ctx.disabled,
                            onSelecting: ($event: any) => (_ctx.setScopeValue(_ctx.nextScopeKey(index), $event.value))
                          }, null, 8, ["value", "get-option-label", "options", "mode", "label-key", "disabled", "onSelecting"])
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}