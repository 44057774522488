import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = ["name", "type", "disabled", "tab-index", "data-testid"]
const _hoisted_2 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_clean_tooltip = _resolveDirective("clean-tooltip")!
  const _directive_clean_html = _resolveDirective("clean-html")!

  return (_openBlock(), _createElementBlock("button", {
    ref: "btn",
    class: _normalizeClass(_ctx.classes),
    name: _ctx.name,
    type: _ctx.type,
    disabled: _ctx.isDisabled,
    "tab-index": _ctx.tabIndex,
    "data-testid": _ctx.componentTestid + '-async-button',
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.clicked && _ctx.clicked(...args)))
  }, [
    (_ctx.isManualRefresh)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: _normalizeClass({'mr-10': _ctx.displayIcon && _ctx.size !== 'sm', 'mr-5': _ctx.displayIcon && _ctx.size === 'sm'})
        }, _toDisplayString(_ctx.t('action.refresh')), 3))
      : _createCommentVNode("", true),
    _cache[1] || (_cache[1] = _createTextVNode()),
    (_ctx.displayIcon)
      ? _withDirectives((_openBlock(), _createElementBlock("i", {
          key: 1,
          class: _normalizeClass({icon: true, 'icon-lg': true, [_ctx.displayIcon]: true, 'mr-0': _ctx.isManualRefresh})
        }, null, 2)), [
          [_directive_clean_tooltip, _ctx.tooltip]
        ])
      : _createCommentVNode("", true),
    _cache[2] || (_cache[2] = _createTextVNode()),
    (_ctx.labelAs === 'text' && _ctx.displayLabel)
      ? _withDirectives((_openBlock(), _createElementBlock("span", _hoisted_2, null, 512)), [
          [_directive_clean_tooltip, _ctx.tooltip],
          [_directive_clean_html, _ctx.displayLabel]
        ])
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}