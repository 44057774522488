import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-wrap" }
const _hoisted_2 = {
  class: "card-title",
  "data-testid": "card-title-slot"
}
const _hoisted_3 = {
  class: "card-body",
  "data-testid": "card-body-slot"
}
const _hoisted_4 = {
  key: 0,
  class: "card-actions",
  "data-testid": "card-actions-slot"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    ref: "cardContainer",
    class: _normalizeClass(["card-container", {'highlight-border': _ctx.showHighlightBorder, 'card-sticky': _ctx.sticky}]),
    "data-testid": "card"
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "title", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.title), 1)
        ])
      ]),
      _cache[1] || (_cache[1] = _createTextVNode()),
      _cache[2] || (_cache[2] = _createElementVNode("hr", null, null, -1)),
      _cache[3] || (_cache[3] = _createTextVNode()),
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "body", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.content), 1)
        ])
      ]),
      _cache[4] || (_cache[4] = _createTextVNode()),
      (_ctx.showActions)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _renderSlot(_ctx.$slots, "actions", {}, () => [
              _createElementVNode("button", {
                class: "btn role-primary",
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.buttonAction && _ctx.buttonAction(...args)))
              }, _toDisplayString(_ctx.buttonText), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ], 2))
}