// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../node_modules/@vue/cli-service/node_modules/css-loader/dist/cjs.js??clonedRuleSet-22.use[1]!../../../node_modules/vue-loader/dist/stylePostLoader.js!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[2]!../../../node_modules/postcss-loader/dist/cjs.js??clonedRuleSet-22.use[3]!../../../node_modules/xterm/css/xterm.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.credential-project[data-v-ba0f8826]{display:flex;min-width:150px}.credential-project .project[data-v-ba0f8826]{flex-basis:50%;flex-grow:0;margin:0 1.75% 0 0}.credential-project.showing-form[data-v-ba0f8826]{flex-grow:1;flex-direction:column}.credential-project.showing-form>.project[data-v-ba0f8826]{margin:0}.credential-project.showing-form>.select-credential-container[data-v-ba0f8826]{display:flex;flex-direction:column;flex-grow:1}.credential-project>.select-credential-container[data-v-ba0f8826]{flex-basis:50%;margin:0 1.75% 0 0}.credential-project>.select-credential-container.view[data-v-ba0f8826]{margin:0}.credential-project>.select-credential-container>.select-credential[data-v-ba0f8826]{flex-grow:1}.auth-button-container[data-v-ba0f8826]{align-content:center;min-width:150px;display:flex;align-items:center;justify-content:center}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
