import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-10",
  "data-testid": "crugke-form"
}
const _hoisted_2 = { class: "row mb-10" }
const _hoisted_3 = { class: "col span-6" }
const _hoisted_4 = { class: "col span-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_AccountAccess = _resolveComponent("AccountAccess")!
  const _component_LabeledInput = _resolveComponent("LabeledInput")!
  const _component_GKENodePoolComponent = _resolveComponent("GKENodePoolComponent")!
  const _component_Tab = _resolveComponent("Tab")!
  const _component_Tabbed = _resolveComponent("Tabbed")!
  const _component_Config = _resolveComponent("Config")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_Networking = _resolveComponent("Networking")!
  const _component_AdvancedOptions = _resolveComponent("AdvancedOptions")!
  const _component_Banner = _resolveComponent("Banner")!
  const _component_ClusterMembershipEditor = _resolveComponent("ClusterMembershipEditor")!
  const _component_Labels = _resolveComponent("Labels")!
  const _component_CruResource = _resolveComponent("CruResource")!

  return (_ctx.$fetchState.pending)
    ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
    : (_openBlock(), _createBlock(_component_CruResource, {
        key: 1,
        ref: "cruresource",
        resource: _ctx.value,
        mode: _ctx.mode,
        "can-yaml": false,
        "done-route": _ctx.doneRoute,
        errors: _ctx.fvUnreportedValidationErrors,
        "validation-passed": _ctx.fvFormIsValid && _ctx.isAuthenticated,
        onError: _cache[39] || (_cache[39] = e=>_ctx.errors=e),
        onFinish: _ctx.save
      }, _createSlots({
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_AccountAccess, {
              credential: _ctx.config.googleCredentialSecret,
              "onUpdate:credential": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.config.googleCredentialSecret) = $event)),
              project: _ctx.config.projectID,
              "onUpdate:project": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.config.projectID) = $event)),
              "is-authenticated": _ctx.isAuthenticated,
              "onUpdate:isAuthenticated": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.isAuthenticated) = $event)),
              mode: _ctx.mode,
              onError: _cache[3] || (_cache[3] = e=>_ctx.errors.push(e)),
              onCancelCredential: _ctx.cancelCredential
            }, null, 8, ["credential", "project", "is-authenticated", "mode", "onCancelCredential"]),
            _cache[49] || (_cache[49] = _createTextVNode()),
            (_ctx.isAuthenticated)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", _hoisted_3, [
                      _createVNode(_component_LabeledInput, {
                        value: _ctx.normanCluster.name,
                        mode: _ctx.mode,
                        "label-key": "generic.name",
                        required: "",
                        rules: _ctx.fvGetAndReportPathRules('clusterName'),
                        "data-testid": "gke-cluster-name",
                        "onUpdate:value": _ctx.setClusterName
                      }, null, 8, ["value", "mode", "rules", "onUpdate:value"])
                    ]),
                    _cache[40] || (_cache[40] = _createTextVNode()),
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_LabeledInput, {
                        value: _ctx.normanCluster.description,
                        mode: _ctx.mode,
                        "label-key": "nameNsDescription.description.label",
                        placeholder: _ctx.t('nameNsDescription.description.placeholder'),
                        "data-testid": "gke-cluster-description",
                        "onUpdate:value": _ctx.setClusterDescription
                      }, null, 8, ["value", "mode", "placeholder", "onUpdate:value"])
                    ])
                  ]),
                  _cache[42] || (_cache[42] = _createTextVNode()),
                  _createElementVNode("div", null, [
                    _createElementVNode("h3", null, _toDisplayString(_ctx.t('gke.accordion.nodePools')), 1)
                  ]),
                  _cache[43] || (_cache[43] = _createTextVNode()),
                  _createVNode(_component_Tabbed, {
                    ref: "pools",
                    "side-tabs": true,
                    "show-tabs-add-remove": _ctx.mode !== 'view',
                    class: "mb-20",
                    onAddTab: _cache[4] || (_cache[4] = ($event: any) => (_ctx.addPool($event))),
                    onRemoveTab: _cache[5] || (_cache[5] = ($event: any) => (_ctx.removePool($event)))
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nodePools, (pool) => {
                        return (_openBlock(), _createBlock(_component_Tab, {
                          key: pool._id,
                          name: pool._id || pool.name,
                          label: pool.name || _ctx.t('gke.notNamed'),
                          error: pool._minMaxValid===false || pool._nameUnique===false
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_GKENodePoolComponent, {
                              version: pool.version,
                              "onUpdate:version": ($event: any) => ((pool.version) = $event),
                              "image-type": pool.config.imageType,
                              "onUpdate:imageType": ($event: any) => ((pool.config.imageType) = $event),
                              "machine-type": pool.config.machineType,
                              "onUpdate:machineType": ($event: any) => ((pool.config.machineType) = $event),
                              "service-account": pool.config.serviceAccount,
                              "onUpdate:serviceAccount": ($event: any) => ((pool.config.serviceAccount) = $event),
                              "disk-type": pool.config.diskType,
                              "onUpdate:diskType": ($event: any) => ((pool.config.diskType) = $event),
                              "disk-size-gb": pool.config.diskSizeGb,
                              "onUpdate:diskSizeGb": ($event: any) => ((pool.config.diskSizeGb) = $event),
                              "local-ssd-count": pool.config.localSsdCount,
                              "onUpdate:localSsdCount": ($event: any) => ((pool.config.localSsdCount) = $event),
                              preemptible: pool.config.preemptible,
                              "onUpdate:preemptible": ($event: any) => ((pool.config.preemptible) = $event),
                              taints: pool.config.taints,
                              "onUpdate:taints": ($event: any) => ((pool.config.taints) = $event),
                              labels: pool.config.labels,
                              "onUpdate:labels": ($event: any) => ((pool.config.labels) = $event),
                              tags: pool.config.tags,
                              "onUpdate:tags": ($event: any) => ((pool.config.tags) = $event),
                              name: pool.name,
                              "onUpdate:name": ($event: any) => ((pool.name) = $event),
                              "initial-node-count": pool.initialNodeCount,
                              "onUpdate:initialNodeCount": ($event: any) => ((pool.initialNodeCount) = $event),
                              "max-pods-constraint": pool.maxPodsConstraint,
                              "onUpdate:maxPodsConstraint": ($event: any) => ((pool.maxPodsConstraint) = $event),
                              autoscaling: pool.autoscaling.enabled,
                              "onUpdate:autoscaling": ($event: any) => ((pool.autoscaling.enabled) = $event),
                              "min-node-count": pool.autoscaling.minNodeCount,
                              "onUpdate:minNodeCount": ($event: any) => ((pool.autoscaling.minNodeCount) = $event),
                              "max-node-count": pool.autoscaling.maxNodeCount,
                              "onUpdate:maxNodeCount": ($event: any) => ((pool.autoscaling.maxNodeCount) = $event),
                              "auto-repair": pool.management.autoRepair,
                              "onUpdate:autoRepair": ($event: any) => ((pool.management.autoRepair) = $event),
                              "auto-upgrade": pool.management.autoUpgrade,
                              "onUpdate:autoUpgrade": ($event: any) => ((pool.management.autoUpgrade) = $event),
                              "oauth-scopes": pool.config.oauthScopes,
                              "onUpdate:oauthScopes": ($event: any) => ((pool.config.oauthScopes) = $event),
                              rules: {
                diskSizeGb: _ctx.fvGetAndReportPathRules('diskSizeGb'),
                initialNodeCount: _ctx.fvGetAndReportPathRules('initialNodeCount'),
                ssdCount: _ctx.fvGetAndReportPathRules('ssdCount'),
                poolName: _ctx.fvGetAndReportPathRules('poolName'),
              },
                              mode: _ctx.mode,
                              "cluster-kubernetes-version": _ctx.config.kubernetesVersion,
                              "machine-type-options": _ctx.machineTypeOptions,
                              "service-account-options": _ctx.serviceAccountOptions,
                              "loading-machine-types": _ctx.loadingMachineTypes,
                              "loading-service-accounts": _ctx.loadingServiceAccounts,
                              "is-new": pool._isNewOrUnprovisioned
                            }, null, 8, ["version", "onUpdate:version", "image-type", "onUpdate:imageType", "machine-type", "onUpdate:machineType", "service-account", "onUpdate:serviceAccount", "disk-type", "onUpdate:diskType", "disk-size-gb", "onUpdate:diskSizeGb", "local-ssd-count", "onUpdate:localSsdCount", "preemptible", "onUpdate:preemptible", "taints", "onUpdate:taints", "labels", "onUpdate:labels", "tags", "onUpdate:tags", "name", "onUpdate:name", "initial-node-count", "onUpdate:initialNodeCount", "max-pods-constraint", "onUpdate:maxPodsConstraint", "autoscaling", "onUpdate:autoscaling", "min-node-count", "onUpdate:minNodeCount", "max-node-count", "onUpdate:maxNodeCount", "auto-repair", "onUpdate:autoRepair", "auto-upgrade", "onUpdate:autoUpgrade", "oauth-scopes", "onUpdate:oauthScopes", "rules", "mode", "cluster-kubernetes-version", "machine-type-options", "service-account-options", "loading-machine-types", "loading-service-accounts", "is-new"])
                          ]),
                          _: 2
                        }, 1032, ["name", "label", "error"]))
                      }), 128))
                    ]),
                    _: 1
                  }, 8, ["show-tabs-add-remove"]),
                  _cache[44] || (_cache[44] = _createTextVNode()),
                  _createVNode(_component_Accordion, {
                    class: "mb-20",
                    title: _ctx.t('gke.accordion.config'),
                    "open-initially": true
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Config, {
                        "kubernetes-version": _ctx.config.kubernetesVersion,
                        "onUpdate:kubernetesVersion": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.config.kubernetesVersion) = $event)),
                        zone: _ctx.config.zone,
                        "onUpdate:zone": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.config.zone) = $event)),
                        region: _ctx.config.region,
                        "onUpdate:region": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.config.region) = $event)),
                        locations: _ctx.config.locations,
                        "onUpdate:locations": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.config.locations) = $event)),
                        "default-image-type": _ctx.defaultImageType,
                        "onUpdate:defaultImageType": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.defaultImageType) = $event)),
                        labels: _ctx.config.labels,
                        "onUpdate:labels": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.config.labels) = $event)),
                        mode: _ctx.mode,
                        "cloud-credential-id": _ctx.config.googleCredentialSecret,
                        "project-id": _ctx.config.projectID,
                        "is-new-or-unprovisioned": _ctx.isNewOrUnprovisioned,
                        "original-version": _ctx.originalVersion,
                        "cluster-id": _ctx.normanCluster.id,
                        "cluster-name": _ctx.config.clusterName
                      }, null, 8, ["kubernetes-version", "zone", "region", "locations", "default-image-type", "labels", "mode", "cloud-credential-id", "project-id", "is-new-or-unprovisioned", "original-version", "cluster-id", "cluster-name"])
                    ]),
                    _: 1
                  }, 8, ["title"]),
                  _cache[45] || (_cache[45] = _createTextVNode()),
                  _createVNode(_component_Accordion, {
                    class: "mb-20",
                    title: _ctx.t('gke.accordion.networking')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Networking, {
                        "kubernetes-version": _ctx.config.kubernetesVersion,
                        "onUpdate:kubernetesVersion": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.config.kubernetesVersion) = $event)),
                        network: _ctx.config.network,
                        "onUpdate:network": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.config.network) = $event)),
                        subnetwork: _ctx.config.subnetwork,
                        "onUpdate:subnetwork": _cache[14] || (_cache[14] = ($event: any) => ((_ctx.config.subnetwork) = $event)),
                        "create-subnetwork": _ctx.config.ipAllocationPolicy.createSubnetwork,
                        "onUpdate:createSubnetwork": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.config.ipAllocationPolicy.createSubnetwork) = $event)),
                        "use-ip-aliases": _ctx.config.ipAllocationPolicy.useIpAliases,
                        "onUpdate:useIpAliases": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.config.ipAllocationPolicy.useIpAliases) = $event)),
                        "network-policy-config": _ctx.config.clusterAddons.networkPolicyConfig,
                        "onUpdate:networkPolicyConfig": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.config.clusterAddons.networkPolicyConfig) = $event)),
                        "enable-network-policy": _ctx.normanCluster.enableNetworkPolicy,
                        "onUpdate:enableNetworkPolicy": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.normanCluster.enableNetworkPolicy) = $event)),
                        "network-policy-enabled": _ctx.config.networkPolicyEnabled,
                        "onUpdate:networkPolicyEnabled": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.config.networkPolicyEnabled) = $event)),
                        "cluster-ipv4-cidr": _ctx.config.clusterIpv4Cidr,
                        "onUpdate:clusterIpv4Cidr": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.config.clusterIpv4Cidr) = $event)),
                        "cluster-secondary-range-name": _ctx.config.ipAllocationPolicy.clusterSecondaryRangeName,
                        "onUpdate:clusterSecondaryRangeName": _cache[21] || (_cache[21] = ($event: any) => ((_ctx.config.ipAllocationPolicy.clusterSecondaryRangeName) = $event)),
                        "services-secondary-range-name": _ctx.config.ipAllocationPolicy.servicesSecondaryRangeName,
                        "onUpdate:servicesSecondaryRangeName": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.config.ipAllocationPolicy.servicesSecondaryRangeName) = $event)),
                        "cluster-ipv4-cidr-block": _ctx.config.ipAllocationPolicy.clusterIpv4CidrBlock,
                        "onUpdate:clusterIpv4CidrBlock": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.config.ipAllocationPolicy.clusterIpv4CidrBlock) = $event)),
                        "services-ipv4-cidr-block": _ctx.config.ipAllocationPolicy.servicesIpv4CidrBlock,
                        "onUpdate:servicesIpv4CidrBlock": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.config.ipAllocationPolicy.servicesIpv4CidrBlock) = $event)),
                        "node-ipv4-cidr-block": _ctx.config.ipAllocationPolicy.nodeIpv4CidrBlock,
                        "onUpdate:nodeIpv4CidrBlock": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.config.ipAllocationPolicy.nodeIpv4CidrBlock) = $event)),
                        "subnetwork-name": _ctx.config.ipAllocationPolicy.subnetworkName,
                        "onUpdate:subnetworkName": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.config.ipAllocationPolicy.subnetworkName) = $event)),
                        "enable-private-endpoint": _ctx.config.privateClusterConfig.enablePrivateEndpoint,
                        "onUpdate:enablePrivateEndpoint": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.config.privateClusterConfig.enablePrivateEndpoint) = $event)),
                        "enable-private-nodes": _ctx.config.privateClusterConfig.enablePrivateNodes,
                        "onUpdate:enablePrivateNodes": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.config.privateClusterConfig.enablePrivateNodes) = $event)),
                        "master-ipv4-cidr-block": _ctx.config.privateClusterConfig.masterIpv4CidrBlock,
                        "onUpdate:masterIpv4CidrBlock": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.config.privateClusterConfig.masterIpv4CidrBlock) = $event)),
                        "enable-master-authorized-network": _ctx.config.masterAuthorizedNetworks.enabled,
                        "onUpdate:enableMasterAuthorizedNetwork": _cache[30] || (_cache[30] = ($event: any) => ((_ctx.config.masterAuthorizedNetworks.enabled) = $event)),
                        "master-authorized-network-cidr-blocks": _ctx.config.masterAuthorizedNetworks.cidrBlocks,
                        "onUpdate:masterAuthorizedNetworkCidrBlocks": _cache[31] || (_cache[31] = ($event: any) => ((_ctx.config.masterAuthorizedNetworks.cidrBlocks) = $event)),
                        rules: {
              masterIpv4CidrBlock: _ctx.fvGetAndReportPathRules('masterIpv4CidrBlock'),
              clusterIpv4CidrBlock: _ctx.fvGetAndReportPathRules('clusterIpv4CidrBlock'),
              nodeIpv4CidrBlock: _ctx.fvGetAndReportPathRules('nodeIpv4CidrBlock'),
              servicesIpv4CidrBlock: _ctx.fvGetAndReportPathRules('servicesIpv4CidrBlock'),
              clusterIpv4Cidr: _ctx.fvGetAndReportPathRules('clusterIpv4Cidr')
            },
                        mode: _ctx.mode,
                        zone: _ctx.config.zone,
                        region: _ctx.config.region,
                        "cloud-credential-id": _ctx.config.googleCredentialSecret,
                        "project-id": _ctx.config.projectID,
                        "original-version": _ctx.originalVersion,
                        "cluster-id": _ctx.normanCluster.id,
                        "cluster-name": _ctx.config.clusterName,
                        "is-new-or-unprovisioned": _ctx.isNewOrUnprovisioned
                      }, null, 8, ["kubernetes-version", "network", "subnetwork", "create-subnetwork", "use-ip-aliases", "network-policy-config", "enable-network-policy", "network-policy-enabled", "cluster-ipv4-cidr", "cluster-secondary-range-name", "services-secondary-range-name", "cluster-ipv4-cidr-block", "services-ipv4-cidr-block", "node-ipv4-cidr-block", "subnetwork-name", "enable-private-endpoint", "enable-private-nodes", "master-ipv4-cidr-block", "enable-master-authorized-network", "master-authorized-network-cidr-blocks", "rules", "mode", "zone", "region", "cloud-credential-id", "project-id", "original-version", "cluster-id", "cluster-name", "is-new-or-unprovisioned"])
                    ]),
                    _: 1
                  }, 8, ["title"]),
                  _cache[46] || (_cache[46] = _createTextVNode()),
                  _createVNode(_component_Accordion, {
                    class: "mb-20",
                    title: _ctx.t('gke.accordion.advanced')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_AdvancedOptions, {
                        "logging-service": _ctx.config.loggingService,
                        "onUpdate:loggingService": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.config.loggingService) = $event)),
                        "monitoring-service": _ctx.config.monitoringService,
                        "onUpdate:monitoringService": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.config.monitoringService) = $event)),
                        "maintenance-window": _ctx.config.maintenanceWindow,
                        "onUpdate:maintenanceWindow": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.config.maintenanceWindow) = $event)),
                        "http-load-balancing": _ctx.config.clusterAddons.httpLoadBalancing,
                        "onUpdate:httpLoadBalancing": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.config.clusterAddons.httpLoadBalancing) = $event)),
                        "horizontal-pod-autoscaling": _ctx.config.clusterAddons.horizontalPodAutoscaling,
                        "onUpdate:horizontalPodAutoscaling": _cache[36] || (_cache[36] = ($event: any) => ((_ctx.config.clusterAddons.horizontalPodAutoscaling) = $event)),
                        "enable-kubernetes-alpha": _ctx.config.enableKubernetesAlpha,
                        "onUpdate:enableKubernetesAlpha": _cache[37] || (_cache[37] = ($event: any) => ((_ctx.config.enableKubernetesAlpha) = $event)),
                        mode: _ctx.mode,
                        "is-new-or-unprovisioned": _ctx.isNewOrUnprovisioned
                      }, null, 8, ["logging-service", "monitoring-service", "maintenance-window", "http-load-balancing", "horizontal-pod-autoscaling", "enable-kubernetes-alpha", "mode", "is-new-or-unprovisioned"])
                    ]),
                    _: 1
                  }, 8, ["title"]),
                  _cache[47] || (_cache[47] = _createTextVNode()),
                  _createVNode(_component_Accordion, {
                    class: "mb-20",
                    title: _ctx.t('gke.accordion.members')
                  }, {
                    default: _withCtx(() => [
                      (_ctx.isEdit)
                        ? (_openBlock(), _createBlock(_component_Banner, {
                            key: 0,
                            color: "info"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.t('cluster.memberRoles.removeMessage')), 1)
                            ]),
                            _: 1
                          }))
                        : _createCommentVNode("", true),
                      _cache[41] || (_cache[41] = _createTextVNode()),
                      (_ctx.canManageMembers)
                        ? (_openBlock(), _createBlock(_component_ClusterMembershipEditor, {
                            key: 1,
                            mode: _ctx.mode,
                            "parent-id": _ctx.normanCluster.id ? _ctx.normanCluster.id : null,
                            onMembershipUpdate: _ctx.onMembershipUpdate
                          }, null, 8, ["mode", "parent-id", "onMembershipUpdate"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["title"]),
                  _cache[48] || (_cache[48] = _createTextVNode()),
                  _createVNode(_component_Accordion, {
                    class: "mb-20",
                    title: _ctx.t('gke.accordion.labels')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_Labels, {
                        value: _ctx.normanCluster,
                        "onUpdate:value": _cache[38] || (_cache[38] = ($event: any) => ((_ctx.normanCluster) = $event)),
                        mode: _ctx.mode
                      }, null, 8, ["value", "mode"])
                    ]),
                    _: 1
                  }, 8, ["title"])
                ]))
              : _createCommentVNode("", true)
          ]),
          _cache[51] || (_cache[51] = _createTextVNode())
        ]),
        _: 2
      }, [
        (!_ctx.hasCredential)
          ? {
              name: "form-footer",
              fn: _withCtx(() => [
                _cache[50] || (_cache[50] = _createElementVNode("div", null, null, -1))
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["resource", "mode", "done-route", "errors", "validation-passed", "onFinish"]))
}