import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-10",
  "data-testid": "cruaks-form"
}
const _hoisted_2 = { class: "row mb-10" }
const _hoisted_3 = { class: "col span-3" }
const _hoisted_4 = { class: "col span-3" }
const _hoisted_5 = { class: "col span-3" }
const _hoisted_6 = { class: "col span-3" }
const _hoisted_7 = { class: "row mb-10 center-inputs" }
const _hoisted_8 = { class: "col span-3" }
const _hoisted_9 = { class: "col span-3" }
const _hoisted_10 = { class: "col span-3" }
const _hoisted_11 = { class: "col span-3" }
const _hoisted_12 = { class: "row mb-10" }
const _hoisted_13 = { class: "col span-3" }
const _hoisted_14 = { class: "col span-3" }
const _hoisted_15 = { class: "row mb-10" }
const _hoisted_16 = { class: "col span-6" }
const _hoisted_17 = { class: "ssh-key" }
const _hoisted_18 = { class: "col span-6" }
const _hoisted_19 = { class: "text-label" }
const _hoisted_20 = { class: "row mb-10" }
const _hoisted_21 = { class: "col span-3" }
const _hoisted_22 = { class: "col span-3" }
const _hoisted_23 = { class: "col span-3" }
const _hoisted_24 = { class: "row mb-10" }
const _hoisted_25 = { class: "col span-3" }
const _hoisted_26 = { class: "col span-3" }
const _hoisted_27 = { class: "col span-3" }
const _hoisted_28 = { class: "row mb-10" }
const _hoisted_29 = { class: "col span-3" }
const _hoisted_30 = { class: "col span-3" }
const _hoisted_31 = { class: "col span-3" }
const _hoisted_32 = { class: "col span-3" }
const _hoisted_33 = { class: "row mb-10" }
const _hoisted_34 = { class: "networking-checkboxes col span-6" }
const _hoisted_35 = {
  key: 0,
  class: "col span-6"
}
const _hoisted_36 = { class: "text-label" }
const _hoisted_37 = { class: "row mb-10" }
const _hoisted_38 = { class: "row mb-10 center-inputs" }
const _hoisted_39 = { class: "col span-4" }
const _hoisted_40 = { class: "col span-4" }
const _hoisted_41 = { class: "col span-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_SelectCredential = _resolveComponent("SelectCredential")!
  const _component_LabeledInput = _resolveComponent("LabeledInput")!
  const _component_LabeledSelect = _resolveComponent("LabeledSelect")!
  const _component_Banner = _resolveComponent("Banner")!
  const _component_AksNodePool = _resolveComponent("AksNodePool")!
  const _component_Tab = _resolveComponent("Tab")!
  const _component_Tabbed = _resolveComponent("Tabbed")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_FileSelector = _resolveComponent("FileSelector")!
  const _component_KeyValue = _resolveComponent("KeyValue")!
  const _component_Accordion = _resolveComponent("Accordion")!
  const _component_ArrayList = _resolveComponent("ArrayList")!
  const _component_ClusterMembershipEditor = _resolveComponent("ClusterMembershipEditor")!
  const _component_Labels = _resolveComponent("Labels")!
  const _component_CruResource = _resolveComponent("CruResource")!

  return (_ctx.$fetchState.pending)
    ? (_openBlock(), _createBlock(_component_Loading, { key: 0 }))
    : (_openBlock(), _createBlock(_component_CruResource, {
        key: 1,
        ref: "cruresource",
        resource: _ctx.value,
        mode: _ctx.mode,
        "can-yaml": false,
        "done-route": _ctx.doneRoute,
        errors: _ctx.fvUnreportedValidationErrors,
        "validation-passed": _ctx.fvFormIsValid,
        onError: _cache[36] || (_cache[36] = e=>_ctx.errors=e),
        onFinish: _ctx.save
      }, _createSlots({
        default: _withCtx(() => [
          _createVNode(_component_SelectCredential, {
            value: _ctx.config.azureCredentialSecret,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.config.azureCredentialSecret) = $event)),
            "data-testid": "cruaks-select-credential",
            mode: _ctx.mode === _ctx.VIEW ? _ctx.VIEW : _ctx.CREATE,
            provider: "azure",
            "default-on-cancel": true,
            "showing-form": _ctx.hasCredential,
            class: "mt-20",
            cancel: _ctx.cancelCredential
          }, null, 8, ["value", "mode", "showing-form", "cancel"]),
          _cache[75] || (_cache[75] = _createTextVNode()),
          (_ctx.hasCredential)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", _hoisted_3, [
                    _createVNode(_component_LabeledInput, {
                      value: _ctx.normanCluster.name,
                      mode: _ctx.mode,
                      "label-key": "generic.name",
                      required: "",
                      rules: _ctx.fvGetAndReportPathRules('name'),
                      "onUpdate:value": _ctx.setClusterName
                    }, null, 8, ["value", "mode", "rules", "onUpdate:value"])
                  ]),
                  _cache[37] || (_cache[37] = _createTextVNode()),
                  _createElementVNode("div", _hoisted_4, [
                    _createVNode(_component_LabeledInput, {
                      value: _ctx.normanCluster.description,
                      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.normanCluster.description) = $event)),
                      mode: _ctx.mode,
                      "label-key": "nameNsDescription.description.label",
                      placeholder: _ctx.t('nameNsDescription.description.placeholder')
                    }, null, 8, ["value", "mode", "placeholder"])
                  ]),
                  _cache[38] || (_cache[38] = _createTextVNode()),
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_component_LabeledSelect, {
                      value: _ctx.config.resourceLocation,
                      "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.config.resourceLocation) = $event)),
                      "data-testid": "cruaks-resourcelocation",
                      mode: _ctx.mode,
                      options: _ctx.locationOptions,
                      "option-label": "displayName",
                      "option-key": "name",
                      "label-key": "aks.location.label",
                      reduce: opt=>opt.name,
                      loading: _ctx.loadingLocations,
                      required: "",
                      disabled: !_ctx.isNewOrUnprovisioned
                    }, null, 8, ["value", "mode", "options", "reduce", "loading", "disabled"])
                  ]),
                  _cache[39] || (_cache[39] = _createTextVNode()),
                  _createElementVNode("div", _hoisted_6, [
                    _createVNode(_component_LabeledSelect, {
                      value: _ctx.config.kubernetesVersion,
                      "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.config.kubernetesVersion) = $event)),
                      "data-testid": "cruaks-kubernetesversion",
                      mode: _ctx.mode,
                      options: _ctx.aksVersionOptions,
                      "label-key": "aks.kubernetesVersion.label",
                      "option-key": "value",
                      "option-label": "label",
                      loading: _ctx.loadingVersions,
                      required: "",
                      rules: _ctx.fvGetAndReportPathRules('kubernetesVersion')
                    }, null, 8, ["value", "mode", "options", "loading", "rules"])
                  ])
                ]),
                _cache[73] || (_cache[73] = _createTextVNode()),
                (_ctx.config.resourceLocation && _ctx.config.resourceLocation.length)
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (!_ctx.canUseAvailabilityZones)
                        ? (_openBlock(), _createBlock(_component_Banner, {
                            key: 0,
                            "label-key": "aks.location.azWarning",
                            color: "warning"
                          }))
                        : _createCommentVNode("", true),
                      _cache[67] || (_cache[67] = _createTextVNode()),
                      _createElementVNode("div", null, [
                        _createElementVNode("h3", null, _toDisplayString(_ctx.t('aks.nodePools.title')), 1)
                      ]),
                      _cache[68] || (_cache[68] = _createTextVNode()),
                      _createVNode(_component_Tabbed, {
                        ref: "pools",
                        "side-tabs": true,
                        "show-tabs-add-remove": _ctx.mode !== 'view',
                        rules: _ctx.fvGetAndReportPathRules('vmSize'),
                        class: "mb-20",
                        onAddTab: _cache[5] || (_cache[5] = ($event: any) => (_ctx.addPool($event))),
                        onRemoveTab: _cache[6] || (_cache[6] = ($event: any) => (_ctx.removePool($event)))
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.nodePools, (pool, i) => {
                            return (_openBlock(), _createBlock(_component_Tab, {
                              key: i,
                              name: pool._id || pool.name,
                              label: pool.name || _ctx.t('aks.nodePools.notNamed'),
                              error: !_ctx.poolIsValid(pool)
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_AksNodePool, {
                                  mode: _ctx.mode,
                                  region: _ctx.config.resourceLocation,
                                  pool: pool,
                                  "vm-size-options": _ctx.vmSizeOptions,
                                  "loading-vm-sizes": _ctx.loadingVmSizes,
                                  isPrimaryPool: i===0,
                                  "can-use-availability-zones": _ctx.canUseAvailabilityZones,
                                  "validation-rules": {name: _ctx.fvGetAndReportPathRules('poolName'),
                                  az: _ctx.fvGetAndReportPathRules('poolAZ'),
                                  count: _ctx.fvGetAndReportPathRules('poolCount'),
                                  min: _ctx.fvGetAndReportPathRules('poolMin'),
                                  max: _ctx.fvGetAndReportPathRules('poolMax'),
                                  minMax: _ctx.fvGetAndReportPathRules('poolMinMax'),
                                  taints: _ctx.fvGetAndReportPathRules('poolTaints')
              },
                                  "original-cluster-version": _ctx.originalVersion,
                                  "cluster-version": _ctx.config.kubernetesVersion,
                                  onRemove: ($event: any) => (_ctx.removePool(pool)),
                                  onVmSizeSet: _cache[4] || (_cache[4] = ($event: any) => (_ctx.touchedVmSize = true))
                                }, null, 8, ["mode", "region", "pool", "vm-size-options", "loading-vm-sizes", "isPrimaryPool", "can-use-availability-zones", "validation-rules", "original-cluster-version", "cluster-version", "onRemove"])
                              ]),
                              _: 2
                            }, 1032, ["name", "label", "error"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["show-tabs-add-remove", "rules"]),
                      _cache[69] || (_cache[69] = _createTextVNode()),
                      _createVNode(_component_Accordion, {
                        "open-initially": true,
                        class: "mb-20",
                        "title-key": "aks.accordions.basics"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_7, [
                            _createElementVNode("div", _hoisted_8, [
                              _createVNode(_component_LabeledInput, {
                                value: _ctx.config.linuxAdminUsername,
                                "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.config.linuxAdminUsername) = $event)),
                                mode: _ctx.mode,
                                "label-key": "aks.linuxAdminUsername.label",
                                disabled: !_ctx.isNewOrUnprovisioned,
                                "placeholder-key": "aks.linuxAdminUsername.placeholder"
                              }, null, 8, ["value", "mode", "disabled"])
                            ]),
                            _cache[40] || (_cache[40] = _createTextVNode()),
                            _createElementVNode("div", _hoisted_9, [
                              _createVNode(_component_LabeledInput, {
                                value: _ctx.config.resourceGroup,
                                "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.config.resourceGroup) = $event)),
                                mode: _ctx.mode,
                                "label-key": "aks.clusterResourceGroup.label",
                                disabled: !_ctx.isNewOrUnprovisioned,
                                rules: _ctx.fvGetAndReportPathRules('resourceGroup'),
                                required: true,
                                "placeholder-key": "aks.clusterResourceGroup.placeholder",
                                tooltip: _ctx.t('aks.clusterResourceGroup.tooltip')
                              }, null, 8, ["value", "mode", "disabled", "rules", "tooltip"])
                            ]),
                            _cache[41] || (_cache[41] = _createTextVNode()),
                            _createElementVNode("div", _hoisted_10, [
                              _createVNode(_component_LabeledInput, {
                                value: _ctx.config.nodeResourceGroup,
                                "onUpdate:value": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.config.nodeResourceGroup) = $event)),
                                mode: _ctx.mode,
                                "label-key": "aks.nodeResourceGroup.label",
                                rules: _ctx.fvGetAndReportPathRules('nodeResourceGroup'),
                                disabled: !_ctx.isNewOrUnprovisioned,
                                "placeholder-key": "aks.nodeResourceGroup.placeholder",
                                tooltip: _ctx.t('aks.nodeResourceGroup.tooltip',null, true )
                              }, null, 8, ["value", "mode", "rules", "disabled", "tooltip"])
                            ]),
                            _cache[42] || (_cache[42] = _createTextVNode()),
                            _createElementVNode("div", _hoisted_11, [
                              _createVNode(_component_Checkbox, {
                                value: _ctx.config.monitoring,
                                "onUpdate:value": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.config.monitoring) = $event)),
                                mode: _ctx.mode,
                                "label-key": "aks.containerMonitoring.label",
                                "data-testid": "aks-monitoring-checkbox"
                              }, null, 8, ["value", "mode"])
                            ])
                          ]),
                          _cache[46] || (_cache[46] = _createTextVNode()),
                          _createElementVNode("div", _hoisted_12, [
                            (_ctx.config.monitoring)
                              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                  _createElementVNode("div", _hoisted_13, [
                                    _createVNode(_component_LabeledInput, {
                                      value: _ctx.config.logAnalyticsWorkspaceGroup,
                                      "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.config.logAnalyticsWorkspaceGroup) = $event)),
                                      mode: _ctx.mode,
                                      "label-key": "aks.logAnalyticsWorkspaceGroup.label",
                                      "data-testid": "aks-log-analytics-workspace-group-input"
                                    }, null, 8, ["value", "mode"])
                                  ]),
                                  _cache[43] || (_cache[43] = _createTextVNode()),
                                  _createElementVNode("div", _hoisted_14, [
                                    _createVNode(_component_LabeledInput, {
                                      value: _ctx.config.logAnalyticsWorkspaceName,
                                      "onUpdate:value": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.config.logAnalyticsWorkspaceName) = $event)),
                                      mode: _ctx.mode,
                                      "label-key": "aks.logAnalyticsWorkspaceName.label",
                                      "data-testid": "aks-log-analytics-workspace-name-input"
                                    }, null, 8, ["value", "mode"])
                                  ])
                                ], 64))
                              : _createCommentVNode("", true)
                          ]),
                          _cache[47] || (_cache[47] = _createTextVNode()),
                          _createElementVNode("div", _hoisted_15, [
                            _createElementVNode("div", _hoisted_16, [
                              _createElementVNode("div", _hoisted_17, [
                                _createVNode(_component_LabeledInput, {
                                  value: _ctx.config.sshPublicKey,
                                  "onUpdate:value": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.config.sshPublicKey) = $event)),
                                  mode: _ctx.mode,
                                  "label-key": "aks.sshPublicKey.label",
                                  type: "multiline",
                                  "placeholder-key": "aks.sshPublicKey.placeholder"
                                }, null, 8, ["value", "mode"]),
                                _cache[44] || (_cache[44] = _createTextVNode()),
                                _createVNode(_component_FileSelector, {
                                  mode: _ctx.mode,
                                  label: _ctx.t('aks.sshPublicKey.readFromFile'),
                                  class: "role-tertiary mt-10",
                                  onSelected: _cache[14] || (_cache[14] = e => _ctx.config.sshPublicKey = e)
                                }, null, 8, ["mode", "label"])
                              ])
                            ]),
                            _cache[45] || (_cache[45] = _createTextVNode()),
                            _createElementVNode("div", _hoisted_18, [
                              _createVNode(_component_KeyValue, {
                                value: _ctx.config.tags,
                                "onUpdate:value": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.config.tags) = $event)),
                                mode: _ctx.mode,
                                title: _ctx.t('aks.tags.label'),
                                "add-label": _ctx.t('aks.tags.addLabel')
                              }, {
                                title: _withCtx(() => [
                                  _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.t('aks.tags.label')), 1)
                                ]),
                                _: 1
                              }, 8, ["value", "mode", "title", "add-label"])
                            ])
                          ])
                        ]),
                        _: 1
                      }),
                      _cache[70] || (_cache[70] = _createTextVNode()),
                      _createVNode(_component_Accordion, {
                        class: "mb-20",
                        "title-key": "aks.accordions.networking",
                        "open-initially": true
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_20, [
                            _createElementVNode("div", _hoisted_21, [
                              _createVNode(_component_LabeledSelect, {
                                value: _ctx.config.loadBalancerSku,
                                "onUpdate:value": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.config.loadBalancerSku) = $event)),
                                "label-key": "aks.loadBalancerSku.label",
                                tooltip: _ctx.t('aks.loadBalancerSku.tooltip'),
                                disabled: !_ctx.canEditLoadBalancerSKU || !_ctx.isNewOrUnprovisioned,
                                options: ['Standard', 'Basic']
                              }, null, 8, ["value", "tooltip", "disabled"])
                            ]),
                            _cache[48] || (_cache[48] = _createTextVNode()),
                            _createElementVNode("div", _hoisted_22, [
                              _createVNode(_component_LabeledInput, {
                                value: _ctx.config.dnsPrefix,
                                "onUpdate:value": _cache[17] || (_cache[17] = ($event: any) => ((_ctx.config.dnsPrefix) = $event)),
                                mode: _ctx.mode,
                                "label-key": "aks.dns.label",
                                disabled: !_ctx.isNewOrUnprovisioned,
                                required: true,
                                rules: _ctx.fvGetAndReportPathRules('dnsPrefix'),
                                "placeholder-key": "aks.dns.placeholder"
                              }, null, 8, ["value", "mode", "disabled", "rules"])
                            ]),
                            _cache[49] || (_cache[49] = _createTextVNode()),
                            _createElementVNode("div", _hoisted_23, [
                              _createVNode(_component_LabeledSelect, {
                                value: _ctx.config.outboundType,
                                "onUpdate:value": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.config.outboundType) = $event)),
                                mode: _ctx.mode,
                                "label-key": "aks.dns.label",
                                disabled: !_ctx.isNewOrUnprovisioned,
                                rules: _ctx.fvGetAndReportPathRules('outboundType'),
                                options: _ctx.outboundTypeOptions,
                                tooltip: _ctx.t('aks.outboundType.tooltip')
                              }, null, 8, ["value", "mode", "disabled", "rules", "options", "tooltip"])
                            ])
                          ]),
                          _cache[62] || (_cache[62] = _createTextVNode()),
                          _createElementVNode("div", _hoisted_24, [
                            _createElementVNode("div", _hoisted_25, [
                              _createVNode(_component_LabeledSelect, {
                                value: _ctx.config.networkPlugin,
                                "onUpdate:value": _cache[19] || (_cache[19] = ($event: any) => ((_ctx.config.networkPlugin) = $event)),
                                mode: _ctx.mode,
                                options: _ctx.networkPluginOptions,
                                "label-key": "aks.networkPlugin.label",
                                disabled: !_ctx.isNewOrUnprovisioned
                              }, null, 8, ["value", "mode", "options", "disabled"])
                            ]),
                            _cache[50] || (_cache[50] = _createTextVNode()),
                            _createElementVNode("div", _hoisted_26, [
                              _createVNode(_component_LabeledSelect, {
                                value: _ctx.networkPolicy,
                                "onUpdate:value": _cache[20] || (_cache[20] = ($event: any) => ((_ctx.networkPolicy) = $event)),
                                mode: _ctx.mode,
                                options: _ctx.networkPolicyOptions,
                                "label-key": "aks.networkPolicy.label",
                                "option-key": "value",
                                reduce: opt=>opt.value,
                                tooltip: _ctx.t('aks.networkPolicy.tooltip'),
                                disabled: !_ctx.isNewOrUnprovisioned
                              }, null, 8, ["value", "mode", "options", "reduce", "tooltip", "disabled"])
                            ]),
                            _cache[51] || (_cache[51] = _createTextVNode()),
                            _createElementVNode("div", _hoisted_27, [
                              _createVNode(_component_LabeledSelect, {
                                value: _ctx.virtualNetwork,
                                "label-key": "aks.virtualNetwork.label",
                                mode: _ctx.mode,
                                options: _ctx.virtualNetworkOptions,
                                loading: _ctx.loadingVirtualNetworks,
                                "option-label": "label",
                                "option-key": "key",
                                disabled: !_ctx.isNewOrUnprovisioned,
                                rules: _ctx.fvGetAndReportPathRules('networkPolicy'),
                                "data-testid": "aks-virtual-network-select",
                                onSelecting: _cache[21] || (_cache[21] = (e)=>_ctx.virtualNetwork = e)
                              }, null, 8, ["value", "mode", "options", "loading", "disabled", "rules"])
                            ])
                          ]),
                          _cache[63] || (_cache[63] = _createTextVNode()),
                          _createElementVNode("div", _hoisted_28, [
                            _createElementVNode("div", _hoisted_29, [
                              _createVNode(_component_LabeledInput, {
                                value: _ctx.config.serviceCidr,
                                "onUpdate:value": _cache[22] || (_cache[22] = ($event: any) => ((_ctx.config.serviceCidr) = $event)),
                                mode: _ctx.mode,
                                "label-key": "aks.serviceCidr.label",
                                tooltip: _ctx.t('aks.serviceCidr.tooltip'),
                                disabled: !_ctx.isNewOrUnprovisioned,
                                rules: _ctx.fvGetAndReportPathRules('serviceCidr')
                              }, null, 8, ["value", "mode", "tooltip", "disabled", "rules"])
                            ]),
                            _cache[52] || (_cache[52] = _createTextVNode()),
                            _createElementVNode("div", _hoisted_30, [
                              _createVNode(_component_LabeledInput, {
                                value: _ctx.config.podCidr,
                                "onUpdate:value": _cache[23] || (_cache[23] = ($event: any) => ((_ctx.config.podCidr) = $event)),
                                mode: _ctx.mode,
                                "label-key": "aks.podCidr.label",
                                disabled: !_ctx.isNewOrUnprovisioned,
                                rules: _ctx.fvGetAndReportPathRules('podCidr')
                              }, null, 8, ["value", "mode", "disabled", "rules"])
                            ]),
                            _cache[53] || (_cache[53] = _createTextVNode()),
                            _createElementVNode("div", _hoisted_31, [
                              _createVNode(_component_LabeledInput, {
                                value: _ctx.config.dnsServiceIp,
                                "onUpdate:value": _cache[24] || (_cache[24] = ($event: any) => ((_ctx.config.dnsServiceIp) = $event)),
                                mode: _ctx.mode,
                                "label-key": "aks.dnsServiceIp.label",
                                tooltip: _ctx.t('aks.dnsServiceIp.tooltip'),
                                disabled: !_ctx.isNewOrUnprovisioned
                              }, null, 8, ["value", "mode", "tooltip", "disabled"])
                            ]),
                            _cache[54] || (_cache[54] = _createTextVNode()),
                            _createElementVNode("div", _hoisted_32, [
                              _createVNode(_component_LabeledInput, {
                                value: _ctx.config.dockerBridgeCidr,
                                "onUpdate:value": _cache[25] || (_cache[25] = ($event: any) => ((_ctx.config.dockerBridgeCidr) = $event)),
                                mode: _ctx.mode,
                                "label-key": "aks.dockerBridgeCidr.label",
                                tooltip: _ctx.t('aks.dockerBridgeCidr.tooltip'),
                                disabled: !_ctx.isNewOrUnprovisioned,
                                rules: _ctx.fvGetAndReportPathRules('dockerBridgeCidr')
                              }, null, 8, ["value", "mode", "tooltip", "disabled", "rules"])
                            ])
                          ]),
                          _cache[64] || (_cache[64] = _createTextVNode()),
                          _createElementVNode("div", _hoisted_33, [
                            _createElementVNode("div", _hoisted_34, [
                              _createVNode(_component_Checkbox, {
                                value: _ctx.value.enableNetworkPolicy,
                                "onUpdate:value": _cache[26] || (_cache[26] = ($event: any) => ((_ctx.value.enableNetworkPolicy) = $event)),
                                mode: _ctx.mode,
                                "label-key": "aks.enableNetworkPolicy.label",
                                disabled: !_ctx.isNewOrUnprovisioned || !_ctx.canEnableNetworkPolicy,
                                tooltip: _ctx.t('aks.enableNetworkPolicy.tooltip')
                              }, null, 8, ["value", "mode", "disabled", "tooltip"]),
                              _cache[55] || (_cache[55] = _createTextVNode()),
                              _createVNode(_component_Checkbox, {
                                value: _ctx.config.httpApplicationRouting,
                                "onUpdate:value": _cache[27] || (_cache[27] = ($event: any) => ((_ctx.config.httpApplicationRouting) = $event)),
                                mode: _ctx.mode,
                                "label-key": "aks.httpApplicationRouting.label"
                              }, null, 8, ["value", "mode"]),
                              _cache[56] || (_cache[56] = _createTextVNode()),
                              _createVNode(_component_Checkbox, {
                                value: _ctx.config.privateCluster,
                                "onUpdate:value": _cache[28] || (_cache[28] = ($event: any) => ((_ctx.config.privateCluster) = $event)),
                                mode: _ctx.mode,
                                "label-key": "aks.privateCluster.label",
                                disabled: !_ctx.canEditPrivateCluster,
                                "data-testid": "cruaks-privateCluster"
                              }, null, 8, ["value", "mode", "disabled"]),
                              _cache[57] || (_cache[57] = _createTextVNode()),
                              _createVNode(_component_Checkbox, {
                                value: _ctx.setAuthorizedIPRanges,
                                "onUpdate:value": _cache[29] || (_cache[29] = ($event: any) => ((_ctx.setAuthorizedIPRanges) = $event)),
                                mode: _ctx.mode,
                                "label-key": "aks.setAuthorizedIPRanges.label",
                                disabled: _ctx.config.privateCluster
                              }, null, 8, ["value", "mode", "disabled"])
                            ]),
                            _cache[58] || (_cache[58] = _createTextVNode()),
                            (_ctx.setAuthorizedIPRanges)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                                  _createVNode(_component_ArrayList, {
                                    value: _ctx.config.authorizedIpRanges,
                                    "onUpdate:value": [
                                      _cache[30] || (_cache[30] = ($event: any) => ((_ctx.config.authorizedIpRanges) = $event)),
                                      _cache[31] || (_cache[31] = ($event: any) => (_ctx.$emit('validationChanged')))
                                    ],
                                    mode: _ctx.mode,
                                    "initial-empty-row": true,
                                    "value-placeholder": "10.0.0.0/14",
                                    label: _ctx.t('aks.authorizedIpRanges.label'),
                                    rules: _ctx.fvGetAndReportPathRules('authorizedIpRanges')
                                  }, {
                                    title: _withCtx(() => [
                                      _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.t('aks.authorizedIpRanges.label')), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["value", "mode", "label", "rules"])
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _cache[65] || (_cache[65] = _createTextVNode()),
                          (_ctx.config.privateCluster)
                            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                                _createElementVNode("div", _hoisted_37, [
                                  _createVNode(_component_Banner, {
                                    color: "warning",
                                    "label-key": "aks.privateCluster.warning",
                                    "data-testid": "cruaks-privateClusterBanner"
                                  })
                                ]),
                                _cache[61] || (_cache[61] = _createTextVNode()),
                                _createElementVNode("div", _hoisted_38, [
                                  _createElementVNode("div", _hoisted_39, [
                                    _createVNode(_component_LabeledInput, {
                                      value: _ctx.config.privateDnsZone,
                                      "onUpdate:value": _cache[32] || (_cache[32] = ($event: any) => ((_ctx.config.privateDnsZone) = $event)),
                                      mode: _ctx.mode,
                                      "label-key": "aks.privateDnsZone.label",
                                      tooltip: _ctx.t('aks.privateDnsZone.tooltip'),
                                      disabled: !_ctx.isNewOrUnprovisioned,
                                      rules: _ctx.fvGetAndReportPathRules('privateDnsZone'),
                                      "data-testid": "cruaks-private-dns-zone"
                                    }, null, 8, ["value", "mode", "tooltip", "disabled", "rules"])
                                  ]),
                                  _cache[59] || (_cache[59] = _createTextVNode()),
                                  _createElementVNode("div", _hoisted_40, [
                                    _createVNode(_component_LabeledInput, {
                                      value: _ctx.config.userAssignedIdentity,
                                      "onUpdate:value": _cache[33] || (_cache[33] = ($event: any) => ((_ctx.config.userAssignedIdentity) = $event)),
                                      mode: _ctx.mode,
                                      "label-key": "aks.userAssignedIdentity.label",
                                      tooltip: _ctx.t('aks.userAssignedIdentity.tooltip'),
                                      disabled: !_ctx.isNewOrUnprovisioned,
                                      "data-testid": "cruaks-user-assigned-identity"
                                    }, null, 8, ["value", "mode", "tooltip", "disabled"])
                                  ]),
                                  _cache[60] || (_cache[60] = _createTextVNode()),
                                  _createElementVNode("div", _hoisted_41, [
                                    _createVNode(_component_Checkbox, {
                                      value: _ctx.config.managedIdentity,
                                      "onUpdate:value": _cache[34] || (_cache[34] = ($event: any) => ((_ctx.config.managedIdentity) = $event)),
                                      mode: _ctx.mode,
                                      "label-key": "aks.managedIdentity.label",
                                      "data-testid": "cruaks-managed-identity"
                                    }, null, 8, ["value", "mode"])
                                  ])
                                ])
                              ], 64))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }),
                      _cache[71] || (_cache[71] = _createTextVNode()),
                      _createVNode(_component_Accordion, {
                        class: "mb-20",
                        "title-key": "aks.accordions.clusterMembers"
                      }, {
                        default: _withCtx(() => [
                          (_ctx.isEdit)
                            ? (_openBlock(), _createBlock(_component_Banner, {
                                key: 0,
                                color: "info"
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.t('cluster.memberRoles.removeMessage')), 1)
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          _cache[66] || (_cache[66] = _createTextVNode()),
                          (_ctx.canManageMembers)
                            ? (_openBlock(), _createBlock(_component_ClusterMembershipEditor, {
                                key: 1,
                                mode: _ctx.mode,
                                "parent-id": _ctx.normanCluster.id ? _ctx.normanCluster.id : null,
                                onMembershipUpdate: _ctx.onMembershipUpdate
                              }, null, 8, ["mode", "parent-id", "onMembershipUpdate"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }),
                      _cache[72] || (_cache[72] = _createTextVNode()),
                      _createVNode(_component_Accordion, {
                        class: "mb-20",
                        "title-key": "aks.accordions.labels"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_Labels, {
                            value: _ctx.normanCluster,
                            "onUpdate:value": _cache[35] || (_cache[35] = ($event: any) => ((_ctx.normanCluster) = $event)),
                            mode: _ctx.mode
                          }, null, 8, ["value", "mode"])
                        ]),
                        _: 1
                      })
                    ], 64))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _cache[76] || (_cache[76] = _createTextVNode())
        ]),
        _: 2
      }, [
        (!_ctx.hasCredential)
          ? {
              name: "form-footer",
              fn: _withCtx(() => [
                _cache[74] || (_cache[74] = _createElementVNode("div", null, null, -1))
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["resource", "mode", "done-route", "errors", "validation-passed", "onFinish"]))
}