import { normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "tooltip",
  "x-placement": "bottom"
}
const _hoisted_2 = { class: "tooltip-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_clean_tooltip = _resolveDirective("clean-tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    ref: "container",
    class: _normalizeClass(["labeled-tooltip", {[_ctx.status]: true, hoverable: _ctx.hover}])
  }, [
    (_ctx.hover)
      ? _withDirectives((_openBlock(), _createElementBlock("i", {
          key: 0,
          class: _normalizeClass([{'hover':!_ctx.value, [_ctx.iconClass]: true}, "icon status-icon"])
        }, null, 2)), [
          [_directive_clean_tooltip, _ctx.isObject(_ctx.value) ? { ...{content: _ctx.value.content, popperClass: [`tooltip-${_ctx.status}`]}, ..._ctx.value } : _ctx.value]
        ])
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("i", {
            class: _normalizeClass([{'hover':!_ctx.value}, "icon status-icon"])
          }, null, 2),
          _cache[2] || (_cache[2] = _createTextVNode()),
          (_ctx.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "tooltip-arrow" }, null, -1)),
                _cache[1] || (_cache[1] = _createTextVNode()),
                _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.value), 1)
              ]))
            : _createCommentVNode("", true)
        ], 64))
  ], 2))
}